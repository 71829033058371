
import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonIcon,
  IonTextarea,
  IonToolbar,
} from "@ionic/vue";
import { reactive } from "@vue/reactivity";
import { watch } from "@vue/runtime-core";
import { cameraOutline, close } from "ionicons/icons";

import config from "@/config/config";
import { getImageURL } from "@/helpers";

import ImageUploader from "./ImageUploader.vue";

export default {
  name: "Image With Caption",
  props: {
    modelValue: Object,
    hideUploadOption: Boolean,
    hideCaption: Boolean,
    hideClose: Boolean,
    color: String,
  },
  emits: ["update:modelValue", "deleteImage"],
  setup(props: any, { emit }: { emit: any }) {
    const state = reactive({
      image: props.modelValue,
      imageURL: "",
    });

    const changeImage = async (imageRef: string) => {
      state.image.ref = imageRef;
      state.imageURL = await getImageURL(imageRef);
    };

    if (state.image.ref)
      getImageURL(state.image.ref).then((url) => (state.imageURL = url));

    watch(state.image, (newImage) => {
      emit("update:modelValue", newImage);
    });

    // Retrieve image URL on load with saved ref

    return {
      state,
      icons: { close, cameraOutline },
      changeImage,
      config,
    };
  },
  components: {
    IonCard,
    IonCardContent,
    IonToolbar,
    IonButton,
    IonButtons,
    IonIcon,
    IonTextarea,
    ImageUploader,
  },
};
