
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonText,
  IonTitle,
  IonToolbar,
} from '@ionic/vue';
import { reactive, toRefs } from '@vue/reactivity';
import { close } from 'ionicons/icons';

import { capitalize, nameToID } from '@/helpers';
import store from '@/store';

export default {
  name: 'New User Modal',
  components: {
    IonButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonIcon,
    IonInput,
    IonItem,
    IonLabel,
    IonText,
    IonTitle,
    IonToolbar,
  },
  props: ['type'],
  emits: ['userAdded', 'didDismiss'],
  setup(props: any, { emit }: { emit: any }) {
    const state = reactive({
      name: '',
      email: '',
    });

    const addUser = async () => {
      if (state.name && store.state.user) {
        if (props.type == 'employee') {
          await store.state.user.addEmployee(state.name, state.email);
        } else if (props.type == 'customer') {
          await store.state.user.addCustomer(state.name, state.email);
        } else {
          throw Error('The specified user type is not valid.');
        }
        emit('userAdded', nameToID(state.name));
      } else throw Error('You must enter a name for the new user.');
    };

    return {
      ...toRefs(state),
      addUser,
      icons: { close },
      capitalize,
    };
  },
};
