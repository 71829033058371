
import { IonCol, IonGrid, IonRow } from "@ionic/vue";
import { reactive } from "@vue/reactivity";
import { watch } from "@vue/runtime-core";
import { add } from "ionicons/icons";

import { newImage } from "@/types/auxiliary";

import AddButton from "../buttons/AddButton.vue";
import ImageWithCaption from "../ImageWithCaption.vue";

export default {
  name: "Images",
  props: {
    modelValue: Array,
  },
  emits: ["update:modelValue"],
  components: {
    ImageWithCaption,
    IonGrid,
    IonRow,
    AddButton,
    IonCol,
  },
  setup(props: any, { emit }: { emit: any }) {
    const state = reactive({
      images: props.modelValue,
    });

    const addImage = () => {
      state.images.unshift(newImage());
    };

    const deleteImage = (index: number) => {
      state.images.splice(index, 1);
    };

    watch(state.images, (newImages) => {
      emit("update:modelValue", newImages);
    });

    return {
      state,
      add,
      addImage,
      deleteImage,
    };
  },
};
