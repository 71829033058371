
import { IonChip, IonIcon, IonLabel } from "@ionic/vue";
import { add } from "ionicons/icons";

export default {
  name: "Add Button",
  props: {
    title: String,
    mode: String,
  },
  components: {
    IonChip,
    IonIcon,
    IonLabel,
  },
  setup() {
    return {
      icons: { add },
    };
  },
};
