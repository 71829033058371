
import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonIcon,
} from "@ionic/vue";
import { cart } from "ionicons/icons";

import { idToName } from "@/helpers";
import router from "@/router";

export default {
  name: "Job Item",
  props: {
    job: Object,
    showCustomer: Boolean,
  },
  setup() {
    return {
      router,
      idToName,
      icons: { cart },
    };
  },
  components: {
    IonCard,
    IonCardHeader,
    IonCardTitle,
    IonCardSubtitle,
    IonIcon,
    IonCardContent,
  },
};
