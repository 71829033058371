
import {
  IonCol,
  IonGrid,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonItemDivider,
  IonItemGroup,
  IonLabel,
  IonList,
  IonRow,
} from "@ionic/vue";
import { computed, reactive } from "@vue/reactivity";
import { onUnmounted } from "@vue/runtime-core";

import config from "@/config/config";
import { InfiniteList } from "@/db";
import { CollectionRef, Splitter } from "@/types/auxiliary";

import ListPlaceholder from "../ListPlaceholder.vue";

interface State {
  infiniteList: InfiniteList;
}

// NOTE: THIS COMPONENT RERENDERS AND RELOADS DATA UPON ANY SEARCH CHANGE
export default {
  name: "Infinite List",
  props: {
    splitters: Array,
    pushQuantity: Number,
    dbRef: {
      type: Object as () => CollectionRef,
    },
    orderByParam: String,
    searchFilter: String,
    sizes: Object,
    type: String,
  },
  setup(props: any) {
    const state = reactive<State>({
      infiniteList: new InfiniteList(
        props.dbRef,
        props.pushQuantity
          ? props.pushQuantity
          : config.constants.PUSH_QUANTITY,
        props.orderByParam,
        props.searchFilter
      ),
    });

    const lists = computed(() => {
      if (state.infiniteList.list.items.length) {
        if (props.splitters) {
          return props.splitters
            .map((splitter: Splitter) => {
              return {
                name: splitter.name,
                items: state.infiniteList.list.items.filter(splitter.filter),
              };
            })
            .filter(
              (list: { name: string; items: Array<any> }) => list.items.length
            );
        }
        return [{ items: state.infiniteList.list.items }];
      }
      return [];
    });

    const initialize = async () => {
      await state.infiniteList.init();
    };

    const loadMore = async (ev: any) => {
      setTimeout(() => {
        state.infiniteList
          .loadNewBatch()
          .then(() => ev.target.complete())
          .catch(() => {
            ev.target.complete();
            ev.target.disabled = true;
          });
      }, 500);
    };

    if (props.dbRef) {
      initialize();
    }

    // Deactivate listeners
    onUnmounted(() => {
      state.infiniteList.destroy();
    });

    return {
      state,
      lists,
      loadMore,
    };
  },
  components: {
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonGrid,
    IonRow,
    IonCol,
    IonList,
    IonItemGroup,
    IonItemDivider,
    IonLabel,
    ListPlaceholder,
  },
};
