
import {
  IonButton,
  IonButtons,
  IonIcon,
  IonInput,
  IonItem,
  IonSearchbar,
  IonText,
  IonToolbar,
} from "@ionic/vue";
import { close } from "ionicons/icons";

import AddButton from "@/components/buttons/AddButton.vue";

export default {
  name: "Search Toolbar",
  emits: ["update:modelValue", "close"],
  props: {
    title: String,
    modelValue: String,
    addAction: Function,
    useDateInput: Boolean,
    disableSearch: Boolean,
    disableAdd: Boolean,
    modalClose: Boolean,
  },
  setup() {
    return {
      icons: { close },
    };
  },
  components: {
    IonToolbar,
    IonSearchbar,
    AddButton,
    IonInput,
    IonItem,
    IonIcon,
    IonButton,
    IonButtons,
    IonText,
  },
};
