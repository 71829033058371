
import { IonButton, IonSpinner } from "@ionic/vue";

export default {
  name: "Button With Spinner",
  props: {
    loading: Boolean,
    title: String,
  },
  components: {
    IonButton,
    IonSpinner,
  },
};
