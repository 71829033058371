
import {
  IonButton,
  IonButtons,
  IonFabButton,
  IonIcon,
  IonItem,
  IonList,
  IonModal,
  IonSelect,
  IonSelectOption,
} from "@ionic/vue";
import { add, cartOutline, hammerOutline } from "ionicons/icons";
import { defineComponent, reactive, ref } from "vue";

import NewUserModal from "@/components/modals/NewUserModal.vue";
import { capitalize, idToName } from "@/helpers";
import store from "@/store";

interface State {
  ids: Array<string>;
}

export default defineComponent({
  id: "User Select",
  props: {
    modelValue: String,
    type: String,
    mode: String,
    displayAsList: Boolean,
    header: Boolean,
  },
  emits: ["update:modelValue", "userAdded"],
  components: {
    IonItem,
    IonSelect,
    IonSelectOption,
    IonIcon,
    IonFabButton,
    IonButton,
    IonButtons,
    IonModal,
    NewUserModal,
    IonList,
  },
  setup(props: any, { emit }: { emit: any }) {
    const state = reactive<State>({
      ids: [],
    });

    const newUserModalIsOpen = ref(false);

    const refreshState = () => {
      if (props.type == "customer" && store.state.user) {
        state.ids = store.state.user.customers.map(
          (customer) => customer.data.id
        );
      } else if (props.type == "employee" && store.state.user)
        state.ids = store.state.user.employees.map(
          (employee) => employee.data.id
        );
      else throw Error("Could not load users.");
    };

    refreshState();

    const addUser = (id: string) => {
      newUserModalIsOpen.value = false;
      setTimeout(() => {
        emit("update:modelValue", id);
        refreshState();
      });
    };

    return {
      state,
      icons: {
        add,
        cartOutline,
        hammerOutline,
      },
      addUser,
      capitalize,
      idToName,
      newUserModalIsOpen,
    };
  },
});
